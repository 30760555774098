import Vue from 'vue'
import Router from 'vue-router'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

import Layout from '../layout/Index.vue'
import Index from '../components/Index.vue'
import Album from '../components/Album.vue'
import Login from '../components/Login.vue'
import Register from '../components/Register.vue'
import Verification from '../components/Verification.vue'
import NotFound from '../components/404.vue'
import { collect } from '../api/statis.js'

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: Index
      }
    ]
  },
  {
    path: '/album',
    component: Layout,
    children: [
      {
        path: ':id',
        component: Album
      }
    ]
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/register',
    component: Register
  },
  {
    path: '/verification',
    component: Verification
  },
  { path: '*', name: '404', component: NotFound }
]

const router = new Router({
  linkActiveClass: 'active',
  routes
})

NProgress.configure({ showSpinner: false })

router.beforeEach(async(to, from, next) => {
  NProgress.start()
  next()
})

router.afterEach((to, from) => {
  collect({ p: to.fullPath, t: new Date().getTime() })
  NProgress.done()
})

export default router
