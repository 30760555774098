<template>
  <div class="login-container">
    <el-card class="login-card" shadow="always">
      <h2 class="login-title">Login</h2>
      <el-form ref="loginForm" :model="loginForm" :rules="rules" label-width="100px">
        <el-form-item label="Email" prop="username">
          <el-input v-model="loginForm.username" placeholder="Enter email" />
        </el-form-item>

        <el-form-item label="Password" prop="password">
          <el-input v-model="loginForm.password" type="password" placeholder="Enter password" />
        </el-form-item>

        <el-form-item>
          <el-button :loading="loading" type="primary" class="login-btn" @click="handleLogin">Login</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { login } from '@/api/user'
// import { setToken } from '@/utils/auth'

export default {
  data() {
    return {
      loading: false,
      loginForm: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: 'Please enter email', trigger: 'blur' },
          { type: 'email', message: 'Please enter a valid email address', trigger: 'blur' }
        ],
        password: [{ required: true, message: 'Please enter password', trigger: 'blur' }]
      }
    }
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          login(this.loginForm).then(result => {
            this.loading = false
            this.$store.dispatch('user/login', this.loginForm).then(() => {
              this.$router.push({ path: this.redirect || '/' })
              this.loading = false
            }).catch(() => {
              this.loading = false
            })
          }).catch(() => {
            this.loading = false
          })
        } else {
          this.$message.error('Please complete the form information!')
        }
      })
    }
  }
}
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.login-card {
  width: 400px;
  padding: 20px;
}

.login-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.login-btn {
  background-color: #49bc85;
  border-color: #49bc85;
}
</style>
