<template>
  <div class="register-container">
    <el-card class="register-card" shadow="always">
      <h2 class="register-title">Register</h2>
      <el-form ref="registerForm" :model="registerForm" :rules="rules" label-width="120px">

        <el-form-item label="Email" prop="username">
          <el-input v-model="registerForm.username" placeholder="Enter email" />
        </el-form-item>

        <el-form-item label="Password" prop="password">
          <el-input v-model="registerForm.password" type="password" placeholder="Enter password" />
        </el-form-item>

        <el-form-item label="Confirm Password" prop="confirmPassword">
          <el-input v-model="registerForm.confirmPassword" type="password" placeholder="Confirm password" />
        </el-form-item>

        <el-form-item label="First Name" prop="firstName">
          <el-input v-model="registerForm.firstName" placeholder="Enter first name" />
        </el-form-item>

        <el-form-item label="Last Name" prop="lastName">
          <el-input v-model="registerForm.lastName" placeholder="Enter last name" />
        </el-form-item>

        <el-form-item label="Nickname" prop="nickname">
          <el-input v-model="registerForm.nickname" placeholder="Enter nickname" />
        </el-form-item>

        <el-form-item label="Whatsapp" prop="whatsapp">
          <el-input v-model="registerForm.whatsapp" placeholder="Enter whatsapp" />
        </el-form-item>

        <el-form-item>
          <el-button :loading="loading" type="primary" class="register-btn" @click="handleRegister">Register</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { register } from '../api/user'

export default {
  data() {
    return {
      loading: false,
      registerForm: {
        username: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        nickname: '',
        whatsapp: ''
      },
      rules: {
        username: [
          { required: true, message: 'Please enter email', trigger: 'blur' },
          { type: 'email', message: 'Please enter a valid email address', trigger: 'blur' }
        ],
        password: [{ required: true, message: 'Please enter password', trigger: 'blur' }],
        confirmPassword: [
          { required: true, message: 'Please confirm password', trigger: 'blur' },
          { validator: this.validateConfirmPassword, trigger: 'blur' }
        ],
        firstName: [{ message: 'Please enter first name', trigger: 'blur' }],
        lastName: [{ message: 'Please enter last name', trigger: 'blur' }],
        nickname: [{ message: 'Please enter nickname', trigger: 'blur' }],
        whatsapp: [{ message: 'Please enter whatsapp number', trigger: 'blur' }]
      }
    }
  },
  methods: {
    validateConfirmPassword(rule, value, callback) {
      if (value !== this.registerForm.password) {
        callback(new Error('Passwords do not match'))
      } else {
        callback()
      }
    },
    handleRegister() {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          this.loading = true
          register(this.registerForm).then(result => {
            this.loading = false
            if (result.code === 0) {
              this.$router.push({
                path: '/verification',
                query: { username: this.registerForm.username }
              })
            } else {
              this.$notify.error(result.message)
            }
          }).catch(() => {
            this.loading = false
          })
        } else {
          this.$message.error('Please complete the form information!')
        }
      })
    }
  }
}
</script>

<style scoped>
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.register-card {
  width: 400px;
  padding: 20px;
}

.register-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.register-btn {
  background-color: #49bc85;
  border-color: #49bc85;
}
</style>
