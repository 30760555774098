<template>
  <el-card class="verification-page" shadow="hover">
    <h2>Verification Code</h2>
    <p>The verification code has been sent to your email: <strong>{{ username }}</strong></p>

    <el-form ref="form" :model="form" status-icon>
      <el-form-item
        label="Verification Code"
        prop="verificationCode"
        :rules="[
          { required: true, message: 'The verification code is required', trigger: 'blur' }
        ]"
      >
        <el-input
          v-model="form.verificationCode"
          placeholder="Enter the verification code"
        />
      </el-form-item>

      <div class="form-actions">
        <el-button class="verification-btn" type="primary" @click="validateCode">Verify</el-button>
      </div>

      <el-alert
        v-if="errorMessage"
        type="error"
        title="Verification failed"
        :description="errorMessage"
        show-icon
        class="error-alert"
      />
    </el-form>
  </el-card>
</template>

<script>
import { validateCode } from '../api/user'

export default {
  name: 'Verification',
  data() {
    return {
      form: {
        verificationCode: ''
      },
      username: this.$route.query.username,
      errorMessage: ''
    }
  },
  methods: {
    async validateCode() {
      this.$refs.form.validate(async(valid) => {
        if (!valid) return
        validateCode({ username: this.username, verificationCode: this.form.verificationCode }).then(result => {
          if (result.code === 0) {
            this.$router.push('/login')
          } else {
            throw new Error('Verification failed!')
          }
        }).catch((error) => {
          this.errorMessage = error.message
        })
      })
    }
  }
}
</script>

  <style scoped>
  .verification-page {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
  }

  .form-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .verification-btn {
  background-color: #49bc85;
  border-color: #49bc85;
}

  .error-alert {
    margin-top: 20px;
  }
  </style>
